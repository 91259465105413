<template>
  <div class="phonebox" style="">
    <div class="phonetitle">生产排程</div>
    <div class="phonecx" style=" margin-top: 0px;">

      <!-- <el-date-picker class="phoneinput" style="width:90%;margin-right: 0px;" v-model="queritem" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker> -->
      <el-select class="phoneinput" style="width:35%;margin-right: 10px; " v-model="status" placeholder="请选择状态：">
        <el-option label="全部" value=" ">
        </el-option>
        <el-option label="未开工" :value="1">
        </el-option>
        <el-option label="未完成 " :value="2">
        </el-option>
        <el-option label="已完成 " :value="3">
        </el-option>
      </el-select>

      <el-select class="phoneinput" style="width:35%;margin-right: 10px; " v-model="iot_id" placeholder="请选择机器">
        <el-option label="全部" value=" ">
        </el-option>
        <el-option v-for="item in deviceList" :key="item.iot_id" :label="item.nick_name" :value="item.iot_id">
        </el-option>
      </el-select>

      <el-button type="primary" @click="cx">查询</el-button>
    </div>
    <div class="phoneMain">
      <el-table :data="list" border style="width: 100%;margin-top: 20px;">
        <el-table-column prop="on_time" label="日期" min-width="15%">
          <template slot-scope="scope">

            <span> {{dayjs(scope.row.on_time).format('MM-DD')}}</span>
            <!-- <span style="color:#52ea07;" v-else>开机</span> -->

          </template>

        </el-table-column>

        <el-table-column prop="iot_id" label="设备" :formatter="swiot_id" min-width="20%"></el-table-column>
        <el-table-column prop="material_name" label="物料" min-width="15%"></el-table-column>
        <el-table-column prop="work_procedure" label="工序" min-width="15%"></el-table-column>
        <el-table-column prop="num" label="数量" min-width="15%"></el-table-column>
        <el-table-column label="状态" min-width="20%">
          <template slot-scope="scope">

            <span :style="scope.row.status === 1?'color:red;':scope.row.status === 2?'color:#52ea07':'color:#666666'">{{scope.row.status_name}}</span>
            <!-- <span style="color:#52ea07;" v-else>开机</span> -->

          </template>
        </el-table-column>
        <!-- <el-table-column prop="total_price" label="总价"></el-table-column> -->

      </el-table>
      <div class="pagbox">
        <el-pagination :pager-count='5' @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="page" :page-sizes="[5,10, 50, 100]" :page-size="limit" layout="sizes, prev, pager, next" :total="total">
        </el-pagination>
      </div>

    </div>

    <div class="phoneFoot">
      <div class="footbox ">
        <router-link :to="{name:'phoneProductionOrder'}"> 生产订单</router-link>
      </div>
      <div class="footbox active">
        <router-link :to="{name:'phoneSchedule'}"> 生产排程</router-link>
      </div>
      <div class="footbox">
        <router-link :to="{name:'home'}"> 实时看板</router-link>
      </div>
      <div class="footbox">
        <router-link :to="{name:'phoneReport'}"> 设备报表</router-link>
      </div>
      <div class="footbox">
        <router-link :to="{name:'phoneStaffreport'}"> 员工报表</router-link>
      </div>
      <div class="footbox">

        <router-link :to="{name:'phoneconsu'}"> 耗材库存</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getschedule, deviceList } from "@/service/api";
export default {
  data () {
    return {
      list: [],
      page: 1,
      limit: 10,
      queritem: '',
      status: '',
      name: '',
      deviceList: [],
      iot_id: '',
      total: 0
    };
  },
  methods: {
    xalert () {
      alert('开发中');
    },
    swiot_id (row) {
      let text = '';

      this.deviceList.forEach(item => {
        if (item.iot_id === row.iot_id) {
          text = item.nick_name;
        }

      });
      return text;
    },
    cx () {
      this.page = 1;
      this.getlist();
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
      this.limit = val;
      this.getlist();
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getlist();
    },
    getlist () {
      let data = { limit: this.limit, page: this.page, iot_id: this.iot_id, status: this.status, group_id: '' };
      data.start_date = this.queritem.length !== 0 ? this.dayjs(this.queritem[0]).format('YYYY-MM-DD HH:mm:ss') : '';
      data.end_date = this.queritem.length !== 0 ? this.dayjs(this.queritem[1]).format('YYYY-MM-DD HH:mm:ss') : '';
      console.log(data);
      getschedule(data).then(res => {
        console.log(res);
        // res.data.list.forEach(item => {
        //   item.order_date = item.order_date ? this.dayjs(item.order_date).format('YYYY-MM-DD') : '无';
        //   item.delivery_date = item.delivery_date ? this.dayjs(item.delivery_date).format('YYYY-MM-DD') : '无';
        // });
        this.list = res.data.list;
        this.total = res.data.total;
      });
    },
    getdeviceList () {
      deviceList({ limit: 999, page: 1 }).then(res => {

        this.deviceList = res.data.list;

      });
    }
  },
  mounted () {
    this.getlist();
    this.getdeviceList();
  }
};
</script>
<style scoped>
@import './css/index.css';
</style>